/********************************************************************
 π-tabber.js
 // TODO: USAGE AND API REFERENCE
 ______________________________________________
 DEPENDENCIES:

 π.js

 ______________________________________________
 DATA ATTRIBUTES:

 ______________________________________________
 MARKUP AND DEFAULTS:

 <div class="new_module">

 </div>

 ______________________________________________
 GENERATED HTML:

 <div class="new_module">

 </div>

 ______________________________________________
 API

 ***************************************************************************************/
// TODO:

(function(){
	function init() {
		π.tabber = {};

		π('.tab-button').forEach(function(button){
			button.onclick = buttonClick;
		});

		π.listen(buildTabbers, 'resize');
		buildTabbers();

		π('.pi-tabber .tab:first-child > .tab-button').forEach(function(btn){btn.click()});

		function buildTabbers() {
			π('.pi-tabber').forEach(getTabHeights);
		}

		function getTabHeights(aTabber) {
			var tabHeights = [];

			aTabber.π('.tab').forEach(function(tab, idx){
				var contentHeight = tab.π1('.content').offset().height;
				tabHeights.push(contentHeight);
				if (tab.hasClass('on')) {
					setTabberHeight(contentHeight, aTabber);
				}
			});

			π.tabber[aTabber.id] = tabHeights;
		}

		function buttonClick() {
			var tabber = this.parent('.pi-tabber');

			// turn off the previous tab
			var currentTab = tabber.π1('.tab.on');
			if (currentTab) {
				currentTab.π1('.content').killClass('on');
				currentTab.killClass('on');
			}

			// turn on the new tab
			var tab = this.parent();
			tab.addClass('on');

			// set new tabber height
			var idx = tabber.π('.tab-button').indexOf(this);
			var contentHeight = π.tabber[tabber.id][idx];
			setTabberHeight(contentHeight, tabber);

			// wait for tabber height before showing content
			setTimeout(function(){
				tab.π1('.content').addClass('on');
			}, millisecondsForTransition(tabber, 'height'));
		}

		function setTabberHeight(contentHeight, tabber) {
			var totalHeight = tabber.π1('.tab').offset().height + contentHeight;
			tabber.css({height: px(totalHeight)});
		}
	}

	// π.mods are loaded after DOMContentLoaded
	π.mods.push(init);
})();