/********************************************************************
 π-rotator.js
 // TODO:  USAGE AND API REFERENCE
 ______________________________________________
 DEPENDENCIES:

 π.js

 ______________________________________________
 DATA ATTRIBUTES:

 ______________________________________________
 MARKUP AND DEFAULTS:

 <div class="new_module">

 </div>

 ______________________________________________
 GENERATED HTML:

 <div class="new_module">

 </div>

 ______________________________________________
 API

 ***************************************************************************************/
// TODO: autoplay
// TODO:
(function(){
	π.rotator = {
		spawn: spawn,
		changeFrame: changeFrame
	};

	function init() {
		π('.pi-rotator').forEach(π.multiFrameDisplay.spawn);
	}

	function spawn(inheritanceObject){
		var el = inheritanceObject.el;
		el.addClass('pi-multi-frame-display');
	}

	function changeFrame(inheritanceObject) {
		var current = inheritanceObject.currentFrame;
		var incoming = inheritanceObject.incomingFrame;

		var side = areWeMovingRight(inheritanceObject) ? 'Right' : 'Left';
		var inClass = 'inFrom' + side, outClass = 'outFrom' + side;

		incoming.addClass(inClass);

		setTimeout(function(){
			incoming.killClass(inClass);
			incoming.addClass('on');
			if (current) current.addClass(outClass);

			if (current) {
				var duration = parseFloat(incoming.css().transitionDuration) * 1000;

				setTimeout(function(){
					current.killClass('on');
					current.killClass(outClass);
				}, duration);
			}
		}, 1);
	}

	function areWeMovingRight(obj){
		var current = obj.currentFrame;
		if (!current) return true;

		var incoming = obj.incomingFrame;
		var currentIdx = current ? current.index() : -1;
		var incomingIdx = incoming.index();

		var wraparound = Math.abs(incomingIdx - currentIdx) > 1;

		if (obj.pagerClicked || !obj.cycle || !wraparound) {
			return incomingIdx > currentIdx;
		}

		return !(wraparound && currentIdx === 0);
	}

	// π.mods are loaded after DOMContentLoaded
	π.mods.push(init);
})();