/********************************************************************
 π-crossfader.js
 // TODO:  USAGE AND API REFERENCE
 ______________________________________________
 DEPENDENCIES:

 π.js

 ______________________________________________
 DATA ATTRIBUTES:

 ______________________________________________
 MARKUP AND DEFAULTS:

 <div class="new_module">

 </div>

 ______________________________________________
 GENERATED HTML:

 <div class="new_module">

 </div>

 ______________________________________________
 API

 ***************************************************************************************/

(function(){
	π.crossfader = {
		spawn: spawn,
		changeFrame: changeFrame
	};

	function init() {
		π('.pi-crossfader').forEach(π.crossfader.spawn);
	}

	function spawn(el){
		π.multiFrameDisplay.spawn(el);
		el.addClass('pi-multi-frame-display');
	}

	function changeFrame(inheritanceObject) {
		var current = inheritanceObject.currentFrame;
		var incoming = inheritanceObject.incomingFrame;

		incoming.addClass('on');
		incoming.css({zIndex: 2});

		if (current) {
			var duration = millisecondsForTransition(incoming, 'opacity');
			current.css({zIndex: 0});

			setTimeout(function(){
				current.killClass('on');
			}, duration);
		}
	}

	π.mods.push(init);
})();