/********************************************************************
 π-sequencer-register.js
 USAGE AND API REFERENCE
 ______________________________________________
 DEPENDENCIES:

 π.js
 π-sequencer.js

 NOTE:
 ______________________________________________
 DATA ATTRIBUTES:

 n/a
 ______________________________________________
 MARKUP AND DEFAULTS:

 n/a

 ______________________________________________
 USAGE:

 within init(), declare your timers (in sequences) thusly:

    (function(){
 		 var sequenceName = "sequenceForThisTimer";

		 newTimer("myFirstTimerID", delayBeforeExecution, sequenceName, function() {
		    // this is the executable
		    someCustomCode();
		    runTimer("mySecondTimerID"); // chain with another timer (or timers) in the sequence
		});

		 newTimer("mySecondTimerID", delayBeforeExecution, sequenceName, function() {
		    // this is the executable
		    someMoreCustomCode();
		});
    })();

 ______________________________________________
 API

 • allTimers: can you guess what this is?
 • allSequences: yup
 • callbacks: the collection of animation methods
 • Timer: the platonic object
 • runTimer: duh
 • killTimer: duh
 • killSequence: kill all the timeouts

 ***************************************************************************************/


π.sequencerRegister = (function(){
	function init() {
		if (!π.sequencer) {
			setTimeout(init, 20);
			return;
		}

		// syntactic sugar. NO SUDDEN MOVES!!!
		function newTimer(id, delay, sequence, callback) {
			π.sequencer.allTimers[id] = new π.sequencer.Timer(id, delay, sequence, callback);
		}

		function runTimer(timerID) {
			π.sequencer.runTimer(timerID);
		}

		function killSequence(groupID) {
			π.sequencer.killSequence(groupID);
		}

		//globals
		var ground = π1(".ground");
		var blueBall = πd("blueBall");
		var yellowBall = πd("yellowBall");

		// sequences
		
		////////////////////////////////
		// THEY MEET
		////////////////////////////////
		(function () {
			var sequenceName = "theyMeet";

			newTimer("blueBallAppears", 0, sequenceName, function() {
				blueBall.addClass("on");
				runTimer("yellowBallAppears");
			});

			newTimer("yellowBallAppears", 800, sequenceName, function() {
				yellowBall.addClass("on");
				runTimer("theyPassInTheNight");
				runTimer("theGroundCreepsIn");
			});

			newTimer("theGroundCreepsIn", 300, sequenceName, function() {
				ground.addClass("on");
				runTimer("theGroundBottomsOut");
			});

			newTimer("theGroundBottomsOut", 1500, sequenceName, function() {
				ground.addClass("done");
			});

			newTimer("theyPassInTheNight", 800, sequenceName, function() {
				blueBall.addClass("passed");
				yellowBall.addClass("passed");
				runTimer("theyRealizeTheirMutualMistake");
			});

			newTimer("theyRealizeTheirMutualMistake", 1300, sequenceName, function() {
				blueBall.addClass("united");
				yellowBall.addClass("united");
			});

			// resetter
			newTimer("resetTheyMeet", 0, sequenceName, function() {
				blueBall.killClass("united");
				blueBall.killClass("passed");
				blueBall.killClass("on");

				yellowBall.killClass("united");
				yellowBall.killClass("passed");
				yellowBall.killClass("on");

				ground.killClass("on");
				ground.killClass("done");

				killSequence(sequenceName);
			})
		})();
	}

	π.mods.push(init);
})();
