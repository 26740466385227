(function(){
	π.listen(init);

	function init(){
		π.clean(init);
		if(π1('#hero')) hotspots.init();
		if(π('#menuBox')) menuFlip.init();
		if(π1('#backToTop')) initBackToTopButton();
		initAnchorScrolling();

		console.log("π loaded");

	}
})();


$(document).ready(function() {
	if($('body.home #hero').length) window.requestAnimationFrame(centerHomeBanner);
	$('.the_content_wrapper table').wrap('<div class="tableBox"></div>');

	var menu = $('#mobileSubMenu');

	$('#subMenuButton').click(function() {
		$('#mobileSubMenu').addClass('poop');

		console.log('poop');

		if(menu.hasClass('open')) {
			menu.removeClass('open');
			menu.slideUp();
		}
		else {
			menu.addClass('open');
			menu.slideDown();
		}
	});

});




/****************** HOTSPOTS ******************/
var hotspots = (function(){
	var currentHotSpot = null;

	function init() {
		π.listen(didYouClickOnABlip, 'click');

		var hotspots = π('.hot-spot');

		hotspots.forEach(function(hotSpot, idx){
			var blip = π.div('blip', 0, idx+1);
			hotSpot.add(blip);

			blip.onclick = function(e){
				e.stopPropagation();

				if (currentHotSpot === hotSpot) {
					hideFlyout(hotSpot, true);
				} else {
					if (currentHotSpot) {
						hideFlyout(currentHotSpot);
					}
					showFlyout(hotSpot);
				}
			};
		});
	}

	function didYouClickOnABlip(){
		if (currentHotSpot) hideFlyout(currentHotSpot);
	}

	function showFlyout(hotspot){
		var MIN_LEFT_PADDING = 20;

		var theMargin = '0';
		var flyout = hotspot.π1('.flyout');
		var flyoutOffset = flyout.offset();
		var offset = flyout.π1('.wrapper').offset();
		var leftOverlap = (flyoutOffset.left - (offset.width/2)) - 20;
		var rightOverlap = window.innerWidth - ((flyoutOffset.right + (offset.width/2)) + 20);

		theMargin = (leftOverlap <= 0) ? '0 0 0 '+Math.abs(leftOverlap)+'px': theMargin;
		theMargin = (rightOverlap <= 0) ? '0 0 0 -'+Math.abs(rightOverlap)+'px': theMargin;
		
		console.log(rightOverlap);
		
		setTimeout(function(){
			hotspot.addClass('on');
			flyout.css({width: px(offset.width), height: px(offset.height), margin: theMargin});
			currentHotSpot = hotspot;
			π1('body').addClass('openFlyout');
		}, 10);
	}

	function hideFlyout(hotspot){
		π1('body').killClass('openFlyout');
		hotspot.killClass('on');
		hotspot.π1('.flyout').css({width: 0, margin: 0});
		currentHotSpot = null;
	}

	return {
		init: init
	};
})();
/****************** /HOTSPOTS ******************/



/****************** BIG MENU ******************/
var menuFlip = (function(){

	var menuTimer;
	var menuItems;
	var menus;

	function init() {
		π1('#menuButton').onclick = flipBigMenu;
	}

	function flipBigMenu(e) {
		var body = π1('body');
		var nav = π1('#menuBox');

		// CLOSE
		if(body.hasClass('openNav')) {
			body.killClass('openNav');
			clearTimeout(menuTimer);
			menuTimer = setTimeout(function() {
				nav.css({display:'none'});
			}, millisecondsForTransition(nav, 'transform'));
		}

		// OPEN
		else {
			nav.css({display:'block'});
			clearTimeout(menuTimer);
			menuTimer = setTimeout(function() {
				body.addClass('openNav');

				if(π1('#searchformNav input[name=s]')) {
					console.log('open');
					setTimeout(function() { π1('#searchformNav input[name=s]').focus(); }, 500);
				}
			}, 10);
		}
	}

	return {
		init: init
	};
})();
/****************** BIG MENU ******************/




function initBackToTopButton() {
	var backButton = π1('#backToTop');
	setInterval(function() {
		if(window.scrollY >= 200 && !backButton.hasClass('show')) {
			backButton.addClass('show');
		}
		else if(window.scrollY < 200 && backButton.hasClass('show')) {
			backButton.killClass('show');
		}
	}, 10);
}




function centerHomeBanner() {
	var parent = $('#hero');
	var image = $('.hero-image');
	var th = parent.height(),
		tw = parent.width(),
		ih = image.height(),
		iw = image.width();

	if((th/tw) > (ih/iw)) 	image.css({ width: 'auto', height: th });
	else 					image.css({ height: 'auto', width: tw });
	window.requestAnimationFrame(centerHomeBanner);
}


/////////////////////////////////////////
// HASHTAG/ANCHOR LINK ANIMATED SCROLLING
/////////////////////////////////////////
function initAnchorScrolling() {
	$('a[href*="#"]').each(function(i) {
		var url = $(this).attr('href').replace(/\/$/, "");
		var name = (url.indexOf("#") !== -1) ? url.substring(url.indexOf("#")+1): url.match(/([^\/]*)\/*$/)[1];
		var testString = /[0-9 _.,!"'/$]/;
		
		// IF NO BAD CHARACTERS EXIST IN THE NAME...
		if(!testString.test(name)) {
			var exists = (!$("a[name='"+name+"']").length && !$("#"+name).length) ? false: true;
			if(exists) {
				var theTarget = (typeof($("a[name='"+name+"']").offset()) !== "undefined") ? $("a[name='"+name+"']"): $("#"+name);
				$(this).click(function(e) {
					e.preventDefault();
					scrollToAnchor(theTarget, name);
				});
			}
		}
	});

	if(window.location.hash) {
		var name = window.location.hash.replace('#','');
		scrollToAnchor($('#'+name), name);
	}
}


function scrollToAnchor(el, name, speed, padding) {
	
	el = $(el);
	speed = (speed) ? speed: 500;
	padding = (padding) ? padding: 90;
	var theTop = el.offset().top-padding;

	$('html, body').stop().animate({ scrollTop:theTop }, speed, 'easeInOutCubic', function() {
		if(name != 'top') window.location.hash = name;
	});
}