/*
 // TODO: fix this API ref
π-drawer: content drawer which lives out-of-sight,
then appears on demand via CSS transition.

There are no DOM manipulations or custom JS objects;
π-drawer is entirely "functional"

**************************************************************
**************               API                **************
**************************************************************

show: addClass('on')
hide: killClass('on')
current: id of the currently shown drawer


 **************************************************************
 **************              USAGE               **************
 **************************************************************

SAMPLE HTML:

<div class="pi-drawer" id="myFirstPiDrawer">
	MY DRAWER CONTENT
</div>
<button pi-drawer-trigger="myFirstPiDrawer">show drawer</button>

 */

(function(){
	π.drawer = {
		show: function(id){
			π.listen(listenForEsc, 'keydown');

			var el = πd(id);
			el.css({display: "block"});

			setTimeout(function () {
				el.addClass("on");
				π1('body').addClass('overlay-on');
			}, 50);

			π.drawer.current = id;
		},
		hide: function(){
			π.clean(listenForEsc, 'keydown');

			var el = πd(π.drawer.current);
			el.killClass("on");

			var speed = parseFloat(el.css().transitionDuration) * 1000;

			setTimeout(function () {
				el.css({display: "none"});
				π1('body').killClass('overlay-on');
			}, speed);

			π.drawer.current = null;
		},
		current: null
	};

	function listenForEsc(e) {
		if (e.which == 27) {
			π.drawer.hide();
		}
	}

	function init() {
		π(".pi-drawer").forEach(function (el) {
			var wrapper = π.div("drawer-wrapper", null, el.innerHTML);
			el.fill(wrapper);

			var closeButton = π.modalCloseButton(π.drawer.hide, el.id);
			wrapper.insertBefore(closeButton, wrapper.childNodes[0]);
		});

		π.setTriggers('drawer', π.drawer);
	}

	π.mods.push(init);
})();
