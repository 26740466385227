/********************************************************************
 π-sequencer.js
 USAGE AND API REFERENCE
 ______________________________________________
 DEPENDENCIES:

 π.js
 π-sequencer-register.js

 ______________________________________________
 DATA ATTRIBUTES:

 Yo, this is best used as an
 abstraction, but if you must:

• (id of the DOMElement is required)
• delay: millis BEFORE execution
• sequence: name of a logical set of timers, e.g. the full set which describes a single animation 'scene';
• callback: just like it sounds. Calls to other timers' runTimer() in here create chains and other complex interactions.

 ______________________________________________
 MARKUP AND DEFAULTS:

 none

 ______________________________________________
 USAGE:

 Complex animations can be cleanly described and built using
 sequences of discrete timers in π-sequencer-register.js
 ______________________________________________
 API

• allTimers: can you guess what this is?
• allSequences: yup
• callbacks: the collection of animation methods
• Timer: the platonic object
• runTimer: duh
• killTimer: duh
• killSequence: kill all the timeouts

 ***************************************************************************************/

(function(){
	π.listen(init);

	function init() {
		π.clean(init);

		π.sequencer = (function(){
			var allTimers = {};
			var allSequences = {};
			var callbacks = {};

			function Timer(id, delay, sequence, callback) {
				this.id = id;
				this.delay = delay;
				this.timeout = null;

				if (callback) {
					callbacks[id] = callback;
				}

				if (sequence) {
					var aSequence = allSequences[sequence];
					if (aSequence) {
						aSequence.push(this);
					}

					else {
						allSequences[sequence] = [this];
					}
				}
			}

			function runTimer(timerID) {
				var thisTimer = allTimers[timerID];
				var thisCallback = callbacks[timerID];
				thisTimer.timeout = setTimeout(thisCallback, thisTimer.delay);
			}

			function killTimer(timerID) {
				var thisTimer = allTimers[timerID];
				if (thisTimer.timeout) clearTimeout(thisTimer.timeout);
			}

			// TODO: runSequence()

			function killSequence(sequenceID) {
				allSequences[sequenceID].forEach(function(timer) {
					killTimer(timer.id);
				});
			}

			return {
				allTimers: allTimers,
				allSequences: allSequences,
				callbacks: callbacks,
				Timer: Timer,
				runTimer: runTimer,
				killTimer: killTimer,
				killSequence: killSequence
			}
		})();
	}
})();